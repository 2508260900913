import graphql from 'graphql-tag';

export const GET_EVENTS = graphql`
  query GetRankingsEvents($skip: Int, $limit: Int, $filter: EventFilter) {
    events(skip: $skip, limit: $limit, filter: $filter) {
      eventId
      collectionId
      id
      level
      participationPoints
      gender
      description
      category
      drawTypeId
      defaultCodeClassId
      tournamentId
      divisionId
      tournament {
        tournamentName
        level
        tournamentStart
        tournamentEnd
        categories
        sectionName
        districtName
        districtCode
        divisions
        drawFormat
      }
      eventPlayers {
        name
        event
        eventId
        collectionId
        playerId
        flightName
        teamName
        playerResults {
          round
          score
          loserId
          winnerId
          loserPartnerId
          winnerPartnerId
          bonusPointsWinValue
          positionOfPlay
        }
        bonusPoints
        participationPoints
        roundPoints
        totalPoints
        singlesPoints
        doublesPoints
        finalPosition
        teamPosition
        adjustmentPoints {
          id
          tournamentId
          eventId
          playerId
          pointsType
          pointsAdjustment
          adjustmentDescription
        }
      }
    }
  }
`;

export const GET_PLAYERS_PLAY_HISTORY = graphql`
  query GetPlayersPlayHistory($skip: Int, $limit: Int, $filter: PlayerPlayHistoryFilter, $sort: EventPlayerSort) {
    getPlayersPlayHistory(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      items {
        playerId
        tournamentId
        tournamentStart
        tournamentEnd
        level
        tournamentName
        eventId
        eventName
        matchFormat
        drawType
        singlesPoints
        doublesPoints
        bonusPoints
        participationPoints
        totalPoints
        pointsAdjusted
        adjustmentDescription
        collectionId
      }
      totalItems
    }
  }
`;

export const GET_PAGINATED_EVENTS = graphql`
  query GetPaginatedEvents($skip: Int, $limit: Int, $filter: EventFilter, $sort: EventSort) {
    eventsPaginated(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      items {
        eventId
        id
        level
        participationPoints
        gender
        description
        category
        drawTypeId
        defaultCodeClassId
        tournamentId
        divisionId
        tournament {
          tournamentName
          level
          tournamentStart
          tournamentEnd
          categories
          sectionName
          districtName
          districtCode
          divisions
          drawFormat
        }
        eventPlayers {
          name
          event
          eventId
          playerId
          playerResults {
            round
            score
            loserId
            winnerId
            loserPartnerId
            winnerPartnerId
            bonusPointsWinValue
          }
          bonusPoints
          participationPoints
          roundPoints
          totalPoints
          singlesPoints
          doublesPoints
          finalPosition
          teamPosition
          adjustmentPoints {
            id
            tournamentId
            eventId
            playerId
            pointsType
            pointsAdjustment
            adjustmentDescription
          }
        }
      }
      totalItems
    }
  }
`;

export const GET_PLAYER_EVENT_POINTS_DOWNLOAD_TEMPLATE = graphql`
  query GetPlayersPlayHistoryDownloadTemplate(
    $skip: Int
    $limit: Int
    $filter: PlayerPlayHistoryFilter
    $sort: EventPlayerSort
  ) {
    getPlayersPlayHistory(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      items {
        playerId
        tournamentId
        tournamentStart
        tournamentEnd
        level
        tournamentName
        eventId
        eventName
        matchFormat
        drawType
        singlesPoints
        doublesPoints
        bonusPoints
        participationPoints
        totalPoints
        pointsAdjusted
        adjustmentDescription
      }
      totalItems
    }
  }
`;

export const GET_PLAYERS_EVENT_POINTS_FOR_LIST = graphql`
  query GetPlayersEventPointsForList($listId: ID!, $playerId: String!) {
    playersEventPointsForList(listId: $listId, playerId: $playerId) {
      id
      eventId
      collectionId
      eventProperties {
        level
        description
        eventPlayers(filter: { playerId: $playerId }) {
          name
          event
          eventId
          playerId
          flightName
          teamName
          playerResults {
            round
            score
            loserId
            winnerId
            loserPartnerId
            winnerPartnerId
            bonusPointsWinValue
            positionOfPlay
          }
          bonusPoints
          participationPoints
          roundPoints
          totalPoints
          singlesPoints
          doublesPoints
          finalPosition
          teamPosition
          adjustmentPoints {
            playerId
            pointsType
            pointsAdjustment
            adjustmentDescription
          }
        }
        tournament {
          tournamentStart
          tournamentEnd
          tournamentName
        }
      }
      playerId
      singlesPoints
      doublesPoints
      bonusPoints
      participationPoints
    }
  }
`;
