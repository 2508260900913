import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { print } from 'graphql';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Panel from 'src/components/panel/panel';
import {
  GET_PLAYER_EVENT_POINTS_DOWNLOAD_TEMPLATE,
  GET_PLAYERS_PLAY_HISTORY,
} from 'src/components/player-ranking/player-events-queries';
import { getEnvConfig } from 'src/config/config';
import { GetPersonByExternalId, GetPersonByExternalIdVariables } from 'src/graphql-types/GetPersonByExternalId';
import {
  GetPlayersPlayHistory,
  GetPlayersPlayHistoryVariables,
  GetPlayersPlayHistory_getPlayersPlayHistory_items as PlayersPlayHistoryItems,
} from 'src/graphql-types/GetPlayersPlayHistory';
import {
  EventDrawTypeEnum,
  EventLevelEnum,
  MatchFormatEnum,
  EventPlayerSortFieldsEnum as SortEnum,
} from 'src/graphql-types/globalUstaTypes';
import { useSpacing } from 'src/hooks/spacing';
import ROUTES from 'src/utils/routes';
import { retrieveColumnPreference } from 'src/utils/storage/local-storage';

import AdminTable, { DataCols } from '../admin-table/admin-table';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import Button from '../button/button';
import CustomDialog from '../custom-dialog/custom-dialog';
import { CustomGrid } from '../custom-grid/custom-grid';
import Dropdown from '../dropdown/dropdown';
import { EditEventPointsDialog } from '../edit-event-points-dialog/edit-event-points-dialog';
import FloatingNotification from '../floating-notification/floating-notification';
import AutomaticDownload from '../generate-report/automatic-download';
import { CSVValueTransform, OnReportUploadStateChange, ReportUploadState } from '../generate-report/generate-report';
import PageHeader from '../page-header/page-header';
import { AdjustmentComponent } from '../player-points-adjustment/player-points-adjustment';
import { GET_PERSON_BY_EXTERNAL_ID } from '../players/players-queries';
import Spinner from '../spinner/spinner';
import { transformSortDirection, useControlledQuery } from '../table-controls/table-controls';
import * as styles from './player-points-player.module.less';

const PlayerInfoItem = ({ label, value, spacing }) => {
  const spacingClass = useSpacing(spacing);
  return (
    <div className={classNames(styles.playerInfoItem, spacingClass)}>
      <div className={styles.playerInfoItemLabel}>{label}:</div>
      <div className={styles.playerInfoItemValue}>{value}</div>
    </div>
  );
};

const getLevelOptions = (t: TFunction) => [
  { label: t('any level'), value: '' },
  { label: 'Level 1', value: EventLevelEnum.L1 },
  { label: 'Level 2', value: EventLevelEnum.L2 },
  { label: 'Level 3', value: EventLevelEnum.L3 },
  { label: 'Level 4', value: EventLevelEnum.L4 },
  { label: 'Level 5', value: EventLevelEnum.L5 },
  { label: 'Level 6', value: EventLevelEnum.L6 },
  { label: 'Level 7', value: EventLevelEnum.L7 },
];

const getDrawTypeOptions = (t: TFunction) => [
  { label: t('any draw type'), value: '' },
  { label: t('draw type ko'), value: EventDrawTypeEnum.SINGLE_ELIMINATION },
  { label: t('round robin'), value: EventDrawTypeEnum.ROUND_ROBIN },
  { label: t('compass'), value: EventDrawTypeEnum.COMPASS },
  {
    label: t('first round losers consolation'),
    value: EventDrawTypeEnum.FIRST_ROUND_LOSERS_CONSOLATION,
  },
  {
    label: t('first match losers consolation'),
    value: EventDrawTypeEnum.FIRST_MATCH_LOSERS_CONSOLATION,
  },
  {
    label: t('modified feed in consolation'),
    value: EventDrawTypeEnum.MODIFIED_FEED_IN,
  },
  {
    label: t('feed in consolation to QF'),
    value: EventDrawTypeEnum.FEED_IN_CONSOLATION_QF,
  },
  {
    label: t('curtis consolation'),
    value: EventDrawTypeEnum.CURTIS_CONSOLATION,
  },
  {
    label: t('feed in consolation to SF'),
    value: EventDrawTypeEnum.FEED_IN_CONSOLATION_SF,
  },
  {
    label: t('feed in consolation to R16'),
    value: EventDrawTypeEnum.FEED_IN_CONSOLATION_R16,
  },
  { label: t('qualifying'), value: EventDrawTypeEnum.QUALIFYING },
  {
    label: t('round robin with playoff'),
    value: EventDrawTypeEnum.ROUND_ROBIN_WITH_PLAYOFF,
  },
  {
    label: t('round robin with playoff of 4'),
    value: EventDrawTypeEnum.ROUND_ROBIN_WITH_PLAYOFF_4,
  },
  {
    label: t('round robin with playoff of 8'),
    value: EventDrawTypeEnum.ROUND_ROBIN_WITH_PLAYOFF_8,
  },
];

enum EditStatusEnum {
  EDITED = 'EDITED',
  UNEDITED = 'UNEDITED',
}

const getEditStatusOptions = (t: TFunction) => [
  { label: t('any edit status'), value: '' },
  { label: t('unedited'), value: EditStatusEnum.UNEDITED },
  { label: t('edited'), value: EditStatusEnum.EDITED },
];

const columnSelectorId = 'player-points-player';

export const PlayerPointsPlayer = ({ id }) => {
  const { t } = useTranslation();
  const storedColumnPreference = useMemo(() => retrieveColumnPreference(columnSelectorId), []);

  const [editDialog, setEditDialog] = useState<PlayersPlayHistoryItems | null>(null);

  const [level, setLevel] = useState('');
  const [drawType, setDrawType] = useState('');
  const [format, setFormat] = useState<MatchFormatEnum | ''>('');
  const [editStatus, setEditStatus] = useState('');

  const [downloadable, setDownloadable] = useState<boolean>(false);
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none');
  const [popupClosed, setPopupClosed] = useState(false);

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    (state) => {
      if (state === 'processing') {
        setPopupClosed(false);
      }
      setReportUploadState(state);
    },
    [setReportUploadState, setPopupClosed],
  );

  const { data, loading } = useQuery<GetPersonByExternalId, GetPersonByExternalIdVariables>(GET_PERSON_BY_EXTERNAL_ID, {
    client: meshGatewayClient,
    variables: { externalId: id },
  });

  const person = data?.personByExternalId;

  const fullName = `${person?.standardGivenName || ''} ${person?.standardFamilyName || ''}`;

  const controlledEventsQueryOptions = {
    client: meshGatewayClient,
    getTotalItems: (d: GetPlayersPlayHistory) => (d?.getPlayersPlayHistory?.totalItems as number) || 0,
    transformVariables: (v: any) => {
      const { sorts = [], limit, offset } = v || {};

      const [sortProperties] = sorts;
      return {
        limit,
        skip: offset,
        filter: {
          playerId: id,
          ...(format ? { matchFormat: format } : {}),
          ...(level ? { level } : {}),
          ...(drawType ? { drawType } : {}),
          ...(editStatus ? { editStatus: editStatus === EditStatusEnum.EDITED } : {}),
        },
        ...(sortProperties
          ? {
              sort: {
                field: sortProperties.property,
                direction: transformSortDirection(sortProperties.sortDirection),
              },
            }
          : {}),
      };
    },
  };

  const {
    data: playersPlayHistoryData,
    loading: loadingPlayHistory,
    refetch,
    controlProps,
  } = useControlledQuery<
    GetPlayersPlayHistory,
    Omit<GetPlayersPlayHistoryVariables, 'limit'> & {
      offset: number;
      limit: number;
    }
  >(GET_PLAYERS_PLAY_HISTORY, controlledEventsQueryOptions);

  const infoItems = {
    'usta id': id,
    DOB: person?.birthDate
      ? t('member date', {
          date: moment(person?.birthDate).local(),
        })
      : t('n/a'),
    section: person?.section?.name || t('n/a'),
    district: person?.district?.name || t('n/a'),
  };

  const resetFilters = () => {
    setLevel('');
    setDrawType('');
    setFormat('');
    setEditStatus('');
  };

  const cols = useMemo<DataCols<PlayersPlayHistoryItems & { id: string }>>(
    () => [
      {
        key: SortEnum['TOURNAMENT_START'],
        title: t('start date'),
        getValue: (e) =>
          t('member date', {
            date: moment(e?.tournamentStart).local(),
          }),

        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['TOURNAMENT_START']] ?? true,
        },
        sort: false,
      },
      {
        key: SortEnum['LEVEL'],
        title: t('level'),
        getValue: (e) => e.level,
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['LEVEL']] ?? true,
        },
      },
      {
        key: SortEnum['TOURNAMENT_NAME'],
        title: t('tournament'),
        getValue: (e) => (
          <button className={styles.linkButton} onClick={() => navigate(`${ROUTES.TOURNAMENTS}/${e.tournamentId}`)}>
            {e?.tournamentName}
          </button>
        ),
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['TOURNAMENT_NAME']] ?? true,
        },
        sort: false,
      },
      // {
      //   key: 'code',
      //   title: t('code'),
      //   getValue: e => e.defaultCodeClassId || t('n/a'),
      //   columnToggle: {
      //     checked: storedColumnPreference?.code ?? true
      //   },
      //   sort: false
      // },
      {
        key: SortEnum['EVENT_NAME'],
        title: t('event'),
        getValue: (e) => e.eventName || t('n/a'),
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['EVENT_NAME']] ?? true,
        },
        sort: false,
      },
      {
        key: SortEnum['MATCH_FORMAT'],
        title: t('match format'),
        // getValue: e => findMatchFormat(e.divisionId, t),
        getValue: (e) => {
          switch (e.matchFormat) {
            case MatchFormatEnum.SINGLES:
              return t('singles');
            case MatchFormatEnum.DOUBLES:
              return t('doubles');
            default:
              return e.matchFormat || t('n/a');
          }
        },
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['MATCH_FORMAT']] ?? true,
        },
      },
      {
        key: 'drawType',
        title: t('draw type'),
        getValue: (e) => e?.drawType || t('n/a'),
        columnToggle: {
          checked: storedColumnPreference?.drawType ?? true,
        },
        sort: false,
      },
      {
        key: SortEnum['SINGLES_POINTS'],
        title: t('singles points'),
        getValue: (e) => {
          if (e.pointsAdjusted && e.matchFormat === MatchFormatEnum.SINGLES) {
            return (
              <AdjustmentComponent adjustmentPoints={e.singlesPoints} adjustmentDescription={e.adjustmentDescription} />
            );
          }

          return e.singlesPoints || 0;
        },
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['SINGLES_POINTS']] ?? true,
        },
        sort: false,
      },
      {
        key: SortEnum['DOUBLES_POINTS'],
        title: t('doubles points'),
        getValue: (e) => {
          if (e.pointsAdjusted && e.matchFormat === MatchFormatEnum.DOUBLES) {
            return (
              <AdjustmentComponent adjustmentPoints={e.doublesPoints} adjustmentDescription={e.adjustmentDescription} />
            );
          }

          return e.doublesPoints || 0;
        },
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['DOUBLES_POINTS']] ?? true,
        },
        sort: false,
      },
      {
        key: SortEnum['BONUS_POINTS'],
        title: t('bonus points'),
        getValue: (e) => e.bonusPoints || 0,
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['BONUS_POINTS']] ?? true,
        },
      },
      {
        key: SortEnum['TOTAL_POINTS'],
        title: t('total points'),
        getValue: (e) => {
          return e.totalPoints;
        },
        columnToggle: {
          checked: storedColumnPreference?.[SortEnum['TOTAL_POINTS']] ?? true,
        },
        sort: false,
      },
      // {
      //   key: 'section',
      //   title: t('section'),
      //   getValue: e => e?.sectionName || t('n/a'),
      //   columnToggle: {
      //     checked: storedColumnPreference?.section ?? false
      //   },
      //   sort: false
      // },
      // {
      //   key: 'district',
      //   title: t('district'),
      //   getValue: e => e?.districtName || t('n/a'),
      //   columnToggle: {
      //     checked: storedColumnPreference?.district ?? false
      //   },
      //   sort: false
      // },
      {
        key: 'edit',
        title: ' ',
        getValue: (event) => (
          <Button level="link" onClick={() => setEditDialog(event)}>
            {t('edit')}
          </Button>
        ),
      },
    ],
    [t],
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t('rankings'), to: ROUTES.RANKINGS, active: true },
          {
            name: t('player points search'),
            to: ROUTES.PLAYER_POINTS,
            highlight: true,
          },
          { name: fullName, active: true },
        ]}
      />
      <CustomGrid spacing={{ margins: { md: 'top' } }}>
        <PageHeader size="md" title={fullName} noPadding />
      </CustomGrid>
      <CustomGrid container spacing={{ margins: { xs: 'top' } }}>
        {Object.entries(infoItems).map(([key, value]) => (
          <PlayerInfoItem key={key} label={t(key)} value={value} spacing={{ margins: { xs: 'right' } }} />
        ))}
      </CustomGrid>
      <Panel>
        <CustomGrid container justify="space-between">
          <Panel.Title>{t('event points')}</Panel.Title>
          <CustomGrid spacing={{ margins: { md: 'left' } }}>
            <AutomaticDownload
              paginator={{ rootFieldPath: 'getPlayersPlayHistory.items' }}
              reportUploadState={reportUploadState}
              onReportUploadStateChange={onReportUploadStateChange}
              generateButtonTitle={t('download csv')}
              downloadable={downloadable}
              setDownloadable={setDownloadable}
              reportQuery={print(GET_PLAYER_EVENT_POINTS_DOWNLOAD_TEMPLATE)}
              reportQueryEndpoint={getEnvConfig().MESH_GATEWAY_GQL_URL}
              reportQueryVariables={{
                filter: {
                  playerId: id,
                },
              }}
              filename={`events-${fullName}`}
              csvTransforms={getCSVTransforms(t)}
            />
            {reportUploadState === 'processing' && !popupClosed && (
              <FloatingNotification
                message={t('preparing download')}
                onClose={() => setPopupClosed(true)}
                variant="download"
                hideCloseButton
              />
            )}
            {reportUploadState === 'downloadable' && !popupClosed && (
              <FloatingNotification
                icon={{ name: 'md-tick-circle', className: styles.tick }}
                message={t('report downloaded')}
                variant="downloaded"
                onClose={() => setPopupClosed(true)}
              />
            )}
          </CustomGrid>
        </CustomGrid>
        <CustomGrid container spacing={{ margins: { sm: 'vertical' } }}>
          <Dropdown
            selected={level}
            options={getLevelOptions(t)}
            placeholder={t('any level')}
            onSelect={(o) => setLevel(o.value)}
            spacing={{ margins: { md: 'right' } }}
          />
          <Dropdown
            selected={drawType}
            options={getDrawTypeOptions(t)}
            placeholder={t('any draw type')}
            onSelect={(o) => setDrawType(o.value)}
            spacing={{ margins: { md: 'right' } }}
          />
          <Dropdown
            selected={format}
            options={[
              { value: '', label: t('any match format') },
              { value: 'SINGLES', label: t('singles') },
              { value: 'DOUBLES', label: t('doubles') },
            ]}
            placeholder={t('any match format')}
            onSelect={(o) => setFormat(o.value)}
            spacing={{ margins: { md: 'right' } }}
          />
          <Dropdown
            selected={editStatus}
            options={getEditStatusOptions(t)}
            placeholder={t('any edit status')}
            onSelect={(o) => setEditStatus(o.value)}
          />
          {(level || drawType || format || editStatus) && (
            <Button level="link" onClick={resetFilters} spacing={{ margins: { sm: 'left' } }} noWidth>
              {t('reset filters')}
            </Button>
          )}
        </CustomGrid>
        <AdminTable
          columns={cols}
          data={playersPlayHistoryData?.getPlayersPlayHistory?.items as any}
          loading={loadingPlayHistory}
          columnSelectorId={columnSelectorId}
          controls={controlProps}
        />
      </Panel>
      <CustomDialog
        title={`${t('edit event points')}: ${fullName}`}
        open={Boolean(editDialog)}
        hideX
        content={
          <EditEventPointsDialog
            setDialog={() => setEditDialog(null)}
            event={editDialog}
            person={person}
            refetchEvents={refetch}
          />
        }
        onClose={() => {}}
      />
    </>
  );
};

function getCSVTransforms(t: TFunction) {
  return [
    {
      key: 'tournamentStart',
      label: t('start date'),
      transforms: [
        {
          operation: CSVValueTransform.ARRAY_FIELD_SELECT,
          parameters: [{ key: 'fieldPath', value: 'tournamentStart' }],
        },
        {
          operation: CSVValueTransform.FORMAT_UTC_DATE,
          parameters: [{ key: 'dateFormat', value: 'MM-DD-YYYY' }],
        },
        {
          operation: CSVValueTransform.ARRAY_JOIN,
          parameters: [{ key: 'delimiter', value: ' / ' }],
        },
      ],
    },
    { key: 'level', label: t('level') },
    { key: 'tournamentName', label: t('tournament') },
    { key: 'eventName', label: t('event') },
    {
      key: 'matchFormat',
      label: t('match format'),
      valueMap: [
        { in: 'SINGLES', out: t('singles') },
        { in: 'DOUBLES', out: t('doubles') },
      ],
    },
    { key: 'drawType', label: t('draw type') },
    {
      key: 'singlesPoints',
      label: t('singles points'),
      valueMap: [
        { in: '', out: '-' },
        { in: 'null', out: '-' },
      ],
    },
    // {
    //   key: 'pointsAdjusted',
    //   label: t('singles adjusted'),
    //   valueMap: [
    //     { in: 'true', out: 'Y' },
    //     { in: 'false', out: 'N' }
    //   ]
    // },
    // {
    //   key: 'adjustmentDescription',
    //   label: t('singles adjusted reason'),
    //   valueMap: [
    //     { in: '', out: '-' },
    //     { in: 'null', out: '-' }
    //   ]
    // },
    {
      key: 'doublesPoints',
      label: t('doubles points'),
      valueMap: [
        { in: '', out: '-' },
        { in: 'null', out: '-' },
      ],
    },
    {
      key: 'pointsAdjusted',
      label: t('points adjusted'),
      valueMap: [
        { in: 'true', out: 'Y' },
        { in: 'false', out: 'N' },
      ],
    },
    {
      key: 'adjustmentDescription',
      label: t('adjustment reason'),
      valueMap: [
        { in: '', out: '-' },
        { in: 'null', out: '-' },
      ],
    },
    // {
    //   key: 'pointsAdjusted',
    //   label: t('doubles adjusted'),
    //   valueMap: [
    //     { in: 'true', out: 'Y' },
    //     { in: 'false', out: 'N' }
    //   ]
    // },
    // {
    //   key: 'adjustmentDescription',
    //   label: t('doubles adjusted reason'),
    //   valueMap: [
    //     { in: '', out: '-' },
    //     { in: 'null', out: '-' }
    //   ]
    // },
    { key: 'bonusPoints', label: t('bonus points') },
    { key: 'totalPoints', label: t('total points') },
  ];
}
