import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PlayerPointsPlayer } from 'src/components/player-points-player/player-points-player';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerPointsProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Player Profile Suspensions" />
      <PlayerPointsPlayer id={id} />
    </Layout>
  );
};

const PlayerPointsProfilePage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/player-points">
      <PlayerPointsProfileRoute path="/:id" />
    </Router>
  );
};

export default PlayerPointsProfilePage;
